.container {
  background-color: var(--cp-black);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.header {
  padding: 14px 35px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 210px;
    height: 55px;
  }

  .demo {
    font-family: 'Public Sans Bold';
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    margin: 0;

    color: var(--cp-white);
    text-decoration: none;
  }
}

.content {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .tab {
    display: flex;
    align-items: center;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    width: 70vw;
    max-width: 1368px;

    color: var(--cp-white);
    text-decoration: none;

    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    transition: color 0.2s ease;

    &:hover {
      color: var(--cp-green);
      transition: color 0.5s ease;
    }
  }
}

.footer {
  display: flex;
  justify-content: end;
  padding: 0 32px 24px;

  .logout {
    font-family: 'Public Sans';
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;

    color: var(--cp-white);
    text-decoration: none;
  }
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
