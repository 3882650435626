.icon {
  margin-left: -16px;
}

.mainTitle {
  margin: 0;
  font-family: 'Public Sans';
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--cp-black);
}

.sectionTitle {
  margin: 32px 0px 16px;
  font-family: 'Public Sans';
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: var(--cp-black);
}

.articleTitle {
  margin: 32px 0px 16px;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--co-black-90);
}

.boldTitle {
  margin: 0;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--cp-black);
}

.cardsTitle {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
