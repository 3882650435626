.header {
  background: var(--cp-blue);
  box-shadow: 0px 0px 30px rgba(17, 38, 146, 0.05);
}

.container {
  margin: 0 auto;
  height: 80px;
  width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;

  .wrapper {
    & button:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;

  .colorPrimary {
    background-color: var(--cp-blue);
  }

  .barColorPrimary {
    background-color: var(--cs-blue);
  }
}

.logoString {
  margin: 0 8px;
  color: var(--cp-white);
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 300px;
  height: 44px;
}
