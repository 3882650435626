body {
  margin: 0;
  box-sizing: border-box;
  background-color: #e9ecef; 
  position: relative;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  // Primary colors
  --cp-green: #00cd73;
  --cp-orange: #ff9a02;
  --cp-blue: #5aaee4;
  --cp-bg: #edf0f6;
  --cp-white: #ffffff;
  --cp-gray: #8a92a6;
  --cp-black: #131313;

  // Secondary colors
  --cs-green: #038a41;
  --cs-red: #fd4b3f;
  --cs-gray: #d5dce3;
  --cs-light-gray: #e6e9ed;
  --cs-blue: #3487bd;

  // Opacity colors
  --co-black-90: #131313e6;
  --co-black-70: #131313b3;
  --co-gray-50: #8b93a780;
  --co-gray-10: #8b93a71a;
  --co-white-90: #ffffffe6;
  --co-white-10: #ffffff1a;

  // Btns colors
  --cb-primary: #5aaee41f;
  --cb-secondary: #3487bd1f;
  --cb-green: #00cd731f;
  --cb-white: #ffffff1f;
}

@font-face {
  font-family: 'Public Sans';
  src: local('Public Sans'),
    url(../assets/fonts/PublicSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Public Sans Medium';
  src: local('Public Sans Medium'),
    url(../assets/fonts/PublicSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Public Sans Bold';
  src: local('Public Sans Bold'),
    url(../assets/fonts/PublicSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter Medium';
  src: local('Inter Medium'),
    url(../assets/fonts/Inter-Medium.ttf) format('truetype');
}

.MuiFormLabel-asterisk {
  color: var(--cs-red);
}
