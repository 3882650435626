.container {
  background: var(--cp-black);
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    height: 80px;
    width: 320px;
  }

  .text {
    font-family: 'Public Sans Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    color: var(--cp-white);
    margin: 0 0 8px 0;
  }

  .loginForm {
    margin: 20px auto;
    width: 400px;
  }
  .input {
    border-radius: 8px;
    background-color: var(--cp-white);
    color: var(--cp-gray);
    font-size: 16px;
    font-family: 'Public Sans Bold';
    letter-spacing: 0.1rem;
    padding: 10px 16px;
    outline: none;
    ::placeholder {
      color: var(--cp-gray);
      font-size: 16px;
      font-family: 'Public Sans Bold';
      letter-spacing: 0.1rem;
    }
    &::after,
    &::before {
      border: none !important;
      outline: none !important;
    }
    &:focus {
      outline: none !important;
      border: none !important;
    }
  }

  .label {
    margin: 24px 0 8px;
    color: var(--cp-white);
    font-size: 16px;
    font-family: 'Public Sans Bold';
    letter-spacing: 0.1rem;
  }
}
