.primaryText {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: var(--co-black-70);
  max-width: 576px;
  width: 100%;
}

.sectionText {
  color: var(--cp-blue);
}
.articleText {
  margin: 0;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--co-black-70);
}

.semiboldText {
  margin: 0;
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--co-black-70);
}

.interMedium {
  font-family: 'Inter Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--co-black-70);
  margin: 0;
}
