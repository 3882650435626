.chip {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 48px;
  border-radius: 40px;
  padding: 12px 20px;
  margin-left: 25px;
  box-sizing: border-box;
  & p {
    color: var(--cp-white);
  }
}
