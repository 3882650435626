.container {
  background-color: transparent;
  margin: 32px auto 96px;
  width: 1376px;
}

.flexContainer {
  display: flex;
}

.labelContainer {
  display: flex;
  width: 100%;
  max-width: 1376px;
  justify-content: flex-end;
  align-items: center;
  bottom: -72px;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

.labelLogo {
  height: 36px;
}

.label {
  font-family: Public Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(138, 146, 166, 1);
}
